:global(.message-text) {
  @apply theme-answer
    wii-mt-1
    wii-inline-block
    wii-max-w-[77%]
    wii-rounded-lg
    wii-px-[0.75em]
    wii-py-[0.5em]
    wii-break-words
    wii-text-msg;
}
