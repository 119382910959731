.divider {
    @apply wii-ml-[-50px] wii-flex wii-justify-evenly wii-py-1;
}

.line {
    @apply wii-text-[#bbb] wii-text-xs wii-relative;
}

.line:before,
.line:after {
    @apply wii-top-[50%] 
           wii-w-[100px] 
           wii-content-['']
           wii-border-t
           wii-border-solid
           wii-border-gray-300
           wii-mr-3
           wii-ml-3
           wii-text-center
           wii-absolute;
}

.line:before {
    @apply wii-right-full;
}
