.message {
  @apply wii-relative wii-w-full wii-py-2 wii-pr-2;

  .message-input {
    border: thin solid var(--theme-color) !important;
    @apply wii-input-sm wii-h-[3em] wii-rounded-[1.5em] wii-w-full;

    &:focus {
      outline: thin solid var(--theme-color) !important;
      border: none;
    }
    // &:disabled {
    //   background-color: var(--theme-background) !important;
    // }
  }

  .icon-group {
    @apply wii-absolute wii-right-4 wii-bottom-0 wii-flex wii-items-center wii-pb-4;
  }
}
