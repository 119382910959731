.menu-icon {
  font-size: 2rem;
}

.menu {
  @apply wii-pointer-events-auto wii-absolute wii-bottom-[4rem] wii-z-10;
}

.menu-button {
  color: var(--color);
  @apply wii-m-1 wii-flex wii-flex-[0_0_30%] wii-flex-col wii-items-center wii-bg-white wii-p-2;
  &:hover {
    background-color: var(--theme-color);
    @apply wii-text-white;
  }
}
