.faq_btn {
    border: 1px solid var(--color);
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 1;
    letter-spacing: 0px;

    @apply wii-max-w-[192px] 
    wii-px-[0.75em] wii-py-[0.25em]
    wii-rounded-3xl
    wii-bg-white 
    wii-message
    wii-overflow-hidden 
    wii-text-black ;
    &:hover {
        @apply theme-color wii-text-white;
    }
}
