.chatbot {
  @screen lg {
    @apply wii-w-20 wii-h-20;
  }

  @apply wii-right-4 wii-bottom-4 wii-cursor-pointer wii-absolute wii-visible wii-w-16 wii-h-16;

  &:hover {
    animation: "rubberBand";
    -webkit-animation: "rubberBand";
    animation-duration: 2s;
  }
}

.chatbot-modal {
  @apply md:wii-w-[375px] wii-w-full wii-z-[1000]
  wii-bg-transparent wii-fixed wii-rounded-2xl
  md:wii-bottom-5 md:wii-right-5 wii-left-auto;

  .chatbot-box {
    @apply md:wii-h-[675px] theme-background wii-p-0
    wii-relative wii-overflow-hidden wii-h-full
     wii-flex wii-flex-col;
  }
}


