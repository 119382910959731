:global(.hyperlink) {
  @apply wii-text-[#0b8de4] hover:wii-text-[#40a9ff] wii-cursor-pointer wii-font-bold;
}

:global(.hyperlink_History) {
  @apply wii-text-[#0b8de4] wii-cursor-auto wii-font-bold;
}

:global(.chatWebPage) {
  border: 0.25px solid #2d94b6;
  @apply wii-bg-[#fffff000] wii-px-[6px] wii-text-[#2d94b6] hover:wii-bg-[hsla(0,0%,91%,.459)]
    hover:wii-text-[#555] wii-rounded-2xl;
  &:hover {
    border: 0.25px solid #bbb;
  }
}

:global(.chatWebPage_History) {
  border: 0.75px solid rgb(216, 215, 215);
  background: rgba(187, 187, 187, 0.301);
  line-height: 18px;
  @apply wii-m-2 wii-cursor-auto;
}

:global(.message-text:hover) {
  & + :global(.timestamp) {
    display: block;
  }
}

.user-message {
  @apply wii-flex-row-reverse wii-justify-items-end;
  & > p {
    @apply wii-mr-3 wii-text-white theme-question;
  }
}