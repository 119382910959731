.line {
    @apply wii-text-[#bbb] wii-text-xs wii-relative
}

.history-message {
    @apply wii-text-center wii-fixed wii-left-0 wii-right-0 wii-z-10 theme-background
}

.history-btn{
    @apply wii-btn wii-btn-sm wii-btn-wide wii-btn-ghost;
    color: var(--color)
}

:global(.ScrollbarsCustom-Wrapper) {
    position: unset !important;
}

:global(.ScrollbarsCustom-Track) {
    height: 100% !important;
    z-index: 10;
    background: var(--theme-background) !important;
    width: 7px !important;
}

:global(.ScrollbarsCustom-Thumb) {
    background-color: var(--theme-background) !important;
    &:hover {
        @apply theme-color #{!important};
    }
}

:global(.ScrollbarsCustom-Track, .ScrollbarsCustom-Thumb) {
    width: 7px !important;
}

:global(.ScrollbarsCustom-TrackY) {
    top: 0 !important;
}


