@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./assets/css/fontello-codes.css";
@import "./assets/css/fontello-embedded.css";
@import "./assets/css/fontello-ie7-codes.css";
@import "./assets/css/fontello-ie7.css";
@import "./assets/css/fontello.css";
@import "animate.css";
@import "../node_modules/swiper/swiper-bundle.css";

@layer utilities {
 
  .theme-color {
    background: transparent linear-gradient(0deg, (var(--theme-color)) 0%, var(--color) 100%) 0% 0% no-repeat padding-box !important;
  }

  .theme-background {
    background: linear-gradient(
      var(--theme-background),
                    var(--theme-background)
    ),
    linear-gradient(#fff, #fff);
  }

  .theme-answer {
    background: var(--theme-answer);
  }

  .theme-question {
    background: var(--theme-question);
  }

  // 圖片&按鈕訊息專用寬度
  .w-spec {
    width: var(--spec-width);
  }

  .h-pic-msg {
    height: var(--pic-message-height);
  }

  .wii-message {
    @apply wii-mt-1 wii-inline-block wii-bg-white wii-text-msg;
  }
}

.login-icon {
  width: 13%;
  padding: 5px;
}

.demarcation {
  display: flex;
  align-items: center;
  justify-content: center;
}

.demarcation::before,
.demarcation::after {
  display: inline-block;
  content: "";
  height: 1px;
  background-color: #ccc;
  width: 40px;
  margin-left: 10px;
  margin-right: 10px;
}

.reply-btn {
  border: 1px solid var(--color);
  @apply wii-min-w-[13%] wii-px-[0.75em] wii-py-[0.25em] wii-rounded-3xl wii-block wii-message;
  &:hover {
    @apply wii-text-white theme-color;
  }
}

.message-content {
  width: calc(theme("width.full") - theme("width.8"));
  @apply wii-pl-1.5 [word-break:break-word] wii-break-words;
}

[data-active="true"] {
  visibility: visible;
  opacity: 1;
}

[data-active="false"] {
  visibility: hidden;
  opacity: 0;
}


body {
  scrollbar-gutter: stable;
}

wii-chatbot > * {
  margin-right: var(--removed-body-scroll-bar-size);
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  vertical-align: middle;
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
  shape-margin: 1rem;
}

/* * DataGrid scrollbar style */
* ::-webkit-scrollbar-track {
  background-color: transparent !important;
}

* ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
}

* ::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f3f3f8 !important;
  border-radius: 8px !important;
}